import $axios from 'axios';

const basicLoginUrl = "/login/basicLogin";
const imgCodeIsShowUrl = "/login/basicLogin/imgCodeIsShow";
const findPriorityRelationUrl = "/relation/findPriorityRelation";

export const basicLoginApi = (params) => {
    return $axios({
        method: 'POST',
        url: basicLoginUrl,
        data: params
    })
}

export const imgCodeIsShowApi = () => {
    return $axios({
        method: 'POST',
        url: imgCodeIsShowUrl
    })
}

export const findPriorityRelationApi = () => {
    const token = sessionStorage.getItem('token');
    return $axios({
        method: 'POST',
        url: findPriorityRelationUrl,
        headers: {
            Authorization: token,
        }
    })
}

